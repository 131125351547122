import '../../App.css'
import Cards from '../Card/Cards'
import Footer from '../Footer/Footer'
import Hero from '../Hero/Hero'
function Home() {
    return(
        <>
            <Hero/>
            <Cards/>
            <Footer/>
        </>
    )
}
export default Home